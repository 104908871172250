<template>
    <div class="search_filter_wrap">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false">
                    <el-option :value="'ti'" :label="$t('subject')"></el-option>
                    <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.enter.native="setFilterFunc(searchInputOption,searchInput)"/>
                <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <!--        <button class="filter_btn two" @click="button>-->
                <el-popover
                    popper-class="top_500"
                    placement=""
                    width="435"
                    trigger="click"
                    :open-delay="300"
                    :offset="0"
                    :append-to-body="false"
                    :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two" @click=""></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{ $t('category') }}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterCategory"
                                                       @change="setFilterFunc('ccid', filterCategory)">
                                        <el-checkbox v-for="data in competitionCategoryList"
                                                     :key="`category${data.cc_idx}`" :label="data.cc_idx">
                                            {{ data.cc_name }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('status') }}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group v-model="filterStatus"
                                                       @change="setFilterFunc('st', filterStatus)">
                                        <el-checkbox :label="0" >{{ $t('scheduled_to_proceed') }}</el-checkbox>
                                        <el-checkbox :label="1" >{{ $t('in_process2') }}</el-checkbox>
                                        <el-checkbox :label="2">{{ $t('under_review') }}</el-checkbox>
                                        <el-checkbox :label="3">{{ $t('p_completed') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('t_prize') }}</div>
                                <div class="price_wrap">
                                    <el-input v-model="filterPrice[0]" class="el_price_input el_input_num"
                                              type="text" @keypress.native.enter="setFilterFunc('pr', filterPrice)"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> Mg</span>
                                    <span class="first_input_step">~</span>
                                    <el-input v-model="filterPrice[1]" class="el_price_input el_input_num"
                                              type="text" @keypress.native.enter="setFilterFunc('pr', filterPrice)"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> Mg</span>
                                </div>
                                <div class="sub_title">{{ $t('dateTime') }}</div>
                                <div class="price_wrap">
                                    <el-date-picker
                                        class="range_time"
                                        v-model="filterDate"
                                        type="daterange"
                                        range-separator="~"
                                        value-format="yyyy-MM-dd"
                                        @change="changeDateFilter()"
                                        start-placeholder="YYYY-MM-DD"
                                        end-placeholder="YYYY-MM-DD"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper_bottom" @click="setFilterFunc('pr', filterPrice)">
                        <button >{{ $t('apply2') }}</button>
                    </div>
                </el-popover>
            </div>


        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
import {mapState} from "vuex";

const {filterConfig} = competitionUtil

export default {
    name: "CompetitionFilterMyCompetitionLayout",
    mixins: [],
    components: {},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sortData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ti',
            searchInput: '',

            scheduledCheck: false,

            filterStatus:[],
            filterCategory:[],
            filterPrice:[],
            filterDate: [],

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            competitionCategoryList: state => state.competitionCategory.competitionCategoryList,
        })
    },
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openModal(name) {
            this.$modal.show(name);
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
        },
        changeDateFilter() {
            if(util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            }else{
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
